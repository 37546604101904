.qust-item-body .qust-item-header.curquest {
    @apply bg-[#733D7A] ;
}

.qust-item-contents p{
    @apply mb-4 mt-0;
}

.qust-item-header.active .qs-action{
   @apply bg-[#FF735C] bg-[url('../../img/split_SGA_LP1/x.png')] bg-no-repeat bg-[1px_1px];
}

.qs-action {
    @apply absolute w-[28px] h-[28px] rounded-full top-[5px] bottom-[5px] left-[-15px] my-auto border-2 border-white bg-[#949494] bg-[url('../../img/split_SGA_LP1/x.png')] bg-no-repeat bg-[0px_-20px] bg-[length:22px];
}

.btn-eff{
    @apply absolute overflow-hidden h-[4px] top-0 z-[9] w-full left-0;
}

small:nth-child(1){
    @apply absolute top-0 right-0 w-full h-[4px] bg-gradient-to-r from-[rgba(228,228,228,0.0941176)] to-[#FF735C] animate-[animateRight_2s_linear_infinite];
}

.optionlist{
    @apply flex flex-wrap gap-[10px];
}

.optionlist li{
    @apply inline-block w-[49%] max-md:w-[100%];
}

.wraper-option input{
    @apply opacity-0 absolute;
}

.option-btn{
    @apply relative p-[10px] rounded-[10px] border border-[#FF735C] cursor-pointer flex items-center text-[16px] shadow-[0px_4px_8px_-2px_rgba(9,30,66,0.25),0px_0px_0px_1px_rgba(9,30,66,0.08)];
}

.option-btn .check{
    @apply inline-block w-[25px] h-[25px] rounded-full border border-[#FF735C] mr-[10px] relative top-[5px];
}

.option-btn.current{
    @apply bg-[#FF735C] border border-[#FF735C] text-[#EFFAFF];
}

.option-btn.current .check{
    @apply border border-[#FF735C] bg-[#733D7A] bg-[url('../../img/split_SGA_LP1/check.png')] bg-no-repeat  bg-center bg-[length:16px];
}

.progress{
    @apply flex h-4 overflow-hidden text-[0.75rem] bg-[#e9ecef] rounded-[0.375rem] shadow-[inset_0_1px_2px_rgba(0,0,0,0.075)];
}

.progress-bar{
    @apply flex flex-col justify-center overflow-hidden text-center whitespace-nowrap bg-[#0d6efd] text-white transition-[width_0.6s_ease];
}

.skip-btn{
    @apply border border-[#FF735C] bg-white text-[#FF735C];
}

.dependents {
    @apply flex flex-nowrap max-sm:flex-wrap;
}

.dependents li{
    @apply max-sm:w-[48%];
}

.dependents .option-btn {
   @apply justify-center;
}

.dependents li:last-child{
    @apply w-full;
}

.qs-option2 li {
   @apply w-1/2;
}

.form-control{
    @apply block w-full p-[0.375rem_0.75rem] text-[1rem] font-normal leading-[1.5] text-[#212529] bg-white border border-[#ced4da] rounded-[0.375rem] appearance-none transition-[border-color_0.15s_ease-in-out,_box-shadow_0.15s_ease-in-out];
}