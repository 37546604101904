.yourdetails h1{ 
    @apply  text-[18px] md:text-[24px] text-[#FF735C] font-semibold my-6 text-center;
}

.yourdetails h3{ 
    @apply  text-[18px] md:text-[22px] text-black font-bold mb-4 relative;
}
.yourdetails h3 span{
    @apply bg-white  z-10 relative pr-2;
}
.yourdetails h3::after {
    @apply h-[1px] w-full absolute top-4 left-0 z-0 bg-[#FF735C];
    content: "";
}
.privacyg{
    @apply  bg-[url("../../img/split_SGA_LP1/pguard.png")] bg-right bg-no-repeat ;
    background-size: 110px;
}

