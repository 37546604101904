@import url(poppins-fontface.css);
@import url(questionnaire.css);
@import url(terms.css);
@import url(select-state.css);
@import url(whychoose.css);
@import url(your-details.css);
@import url(review-summary.css);
@import url(thankyou.css);
@import url(footer-pages.css);
@import url(doctor-details.css);

@tailwind base;
@tailwind components;
@tailwind utilities;

.mb-0{
    margin-bottom: 10px;
}
@layer base {
    html{
        font-family: "Poppins", sans-serif;
    }
   }
 
 @layer components{

header{
    @apply bg-[#FF735C]
}
   
.banner-section{
    @apply bg-[url("../../img/split_SGA_LP1/banner-bg.webp")] bg-right bg-no-repeat bg-[#FF735C] md:min-h-[500px] min-h-[670px] flex md:items-center items-start;
}

.banner-section h1{
    @apply mb-6 text-[26px] lg:text-[33px] lg:leading-[51px] text-white font-[600] leading-[36px];
}
.banner-section h1 span{
    @apply text-[16px] lg:text-[33px] lg:leading-[51px] text-white font-[600] leading-[26px] block md:inline;
}

.subtext {
    font-size: 1.4rem;
}
.insur-text{
    @apply md:px-12 px-4 py-4 text-[#FF735C] md:text-[22px] text-[17px] inline-flex relative;
}
.insur-text::before {
    @apply absolute w-[55px] h-[64px] -right-3 -top-[23px];
    background: url(../../img/split_SGA_LP1/shape-c.png);
    content: "";
    background-size: 60px;
}
.insur-text::after {
    @apply absolute w-[55px] h-[64px] -left-[17px] -bottom-3;
    background: url(../../img/split_SGA_LP1/shape-c.png);
    content: "";
    background-size: 60px;
    transform: rotate(8deg);
    -webkit-transform: rotate(8deg);
    -o-transform: rotate(8deg);
}


/* banner-right */

.maritalstatus-wrap{
    @apply relative pt-6 mt-4;
}


.maritalstatus-wrap .line-bg {
    @apply hidden absolute h-[350px] z-0;
	 transform: translate(100px, -92px);
}
 .maritalstatus-wrap .line-bg .lineeff {
	 stroke-dasharray: 2300;
	 stroke-dashoffset: 2300;
	 animation: dash 3s linear forwards;
}
 @keyframes dash {
	 to {
		 stroke-dashoffset: 0;
	}
}
 .maritalstatus-wrap .insurancefor {
    @apply relative z-[5];
	 gap: 10px;
	 background-size: 250px;
}
 .maritalstatus-wrap .insurancefor li:hover .marital-circle {
	 border: 6px solid #733d7a;
	 -webkit-transition: all ease-in 0.5s;
	 -o-transition: all ease-in 0.5s;
	 transition: all ease-in 0.5s;
}

 .marital-circle-border {
    border: 6px solid #733d7a;
    -webkit-transition: all ease-in 0.5s;
    -o-transition: all ease-in 0.5s;
    transition: all ease-in 0.5s;
}


 .maritalstatus-wrap .insurancefor li .inner {
	 box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0) 0px -3px 0px inset;
}
 .maritalstatus-wrap .insurancefor li .marital-circle {
    @apply  w-[130px] h-[130px] flex items-center justify-center p-2 text-[13px] leading-[18px] cursor-pointer;
	 border: 6px solid #c5c5c5;
	 -webkit-transition: all ease-in 0.5s;
	 -o-transition: all ease-in 0.5s;
	 transition: all ease-in 0.5s;
	 background: linear-gradient(135deg, #fff 0%, #effeff 37%, #c8d7dc 100%);
}
 .maritalstatus-wrap .insurancefor li .marital-circle img {
	 height: 50px;
}
 .maritalstatus-wrap .insurancefor li .circle-item {
	 -webkit-animation: scale-up-center 0.1s cubic-bezier(0.39, 0.575, 0.565, 1) both;
	 animation: scale-up-center 0.1s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
 @-webkit-keyframes scale-up-center {
	 0% {
		 -webkit-transform: scale(0.5);
		 transform: scale(0.5);
		 opacity: 0;
	}
	 100% {
		 -webkit-transform: scale(1);
		 transform: scale(1);
		 opacity: 1;
	}
}
 @keyframes scale-up-center {
	 0% {
		 -webkit-transform: scale(0.5);
		 transform: scale(0.5);
		 opacity: 0;
	}
	 100% {
		 -webkit-transform: scale(1);
		 transform: scale(1);
		 opacity: 1;
	}
}
 .maritalstatus-wrap .insurancefor li:first-child .circle-item {
	 animation-delay: 1s;
}
 .maritalstatus-wrap .insurancefor li:nth-child(2) .circle-item {
	 animation-delay: 1.5s;
}
 .maritalstatus-wrap .insurancefor li:nth-child(3) .circle-item {
	 animation-delay: 2s;
}
 .maritalstatus-wrap .insurancefor li:nth-child(4) .circle-item {
	 animation-delay: 2.5s;
}
 

.line-bg {
    @apply absolute h-[350px] z-0;
    transform: translate(100px, -92px);

}

.lineeff {
    stroke-dasharray: 2300;
    stroke-dashoffset: 2300;
    animation: dash 3s linear forwards;
}
@media screen and (min-width: 991px) {
    .loadertop{
        margin-top: '4rem'
    }
    .maritalstatus-wrap .line-bg {
        display: block;
   }
    .maritalstatus-wrap .insurancefor li:last-child .marital-circle {
        font-size: 14px;
   }
    .maritalstatus-wrap .insurancefor li:first-child {
        transform: translate(-25px, 85px);
   }
    .maritalstatus-wrap .insurancefor li:nth-child(2) {
        transform: translate(-2px, 42px);
   }
    .maritalstatus-wrap .insurancefor li:nth-child(3) {
        transform: translate(10px, -18px);
   }
    .maritalstatus-wrap .insurancefor li:nth-child(4) {
        transform: translate(15px, -130px);
   }
}
@media screen and (min-width: 1200px) {
    .loadertop{
        margin-top: '4rem'
    }
    .maritalstatus-wrap {
        -webkit-transform: translate(0px, 50px);
        -o-transform: translate(0px, 50px);
        transform: translate(0px, 50px);
   }
    .maritalstatus-wrap .insurancefor li:last-child .marital-circle {
        font-size: 13px;
   }
    .maritalstatus-wrap .insurancefor li:first-child {
        transform: translate(0px, 148px);
   }
    .maritalstatus-wrap .insurancefor li:nth-child(2) {
        transform: translate(-2px, 42px);
   }
    .maritalstatus-wrap .insurancefor li:nth-child(3) {
        transform: translate(10px, -18px);
   }
    .maritalstatus-wrap .insurancefor li:nth-child(4) {
        transform: translate(15px, -120px);
   }
}
@media screen and (min-width: 1400px) {
    .loadertop{
        margin-top: '4rem'
    }
    .maritalstatus-wrap .insurancefor li .marital-circle {
        @apply w-[140px] h-[140px] text-[15px];
   }
    .maritalstatus-wrap .insurancefor li:last-child .marital-circle {
        font-size: 15px;
   }
    .maritalstatus-wrap .insurancefor li:nth-child(4) {
        transform: translate(15px, -153px);
   }
}

/*  */
.loadertop{
    margin-top: '-2rem'
}

.insurancefor {
    @apply md:justify-between justify-center flex flex-wrap h-full relative;
    z-index: 5;
    gap: 10px;
    background-size: 250px;
}

.fadein-eff {
    opacity: 0;
    transition: all 1.5s;
    transform: translate(0, 100px)
}
.fadein-eff.visible {
    opacity: 1;
    transform: translate(0, 0)
}
/*  */
.banner-belowsection{
    @apply bg-[#733D7A] py-[0px] relative text-white;
}

.img-qut {
    @apply relative -mt-[56px] md:max-w-[22%] max-w-[50%]

}
.qut-text{
    @apply md:max-w-[65%] w-full;
}
.qut-text p{
    @apply text-[18px] md:text-[22px] mb-4 md:text-left text-center;
}

.logos-wraper h2{
    @apply text-[30px] md:text-[40px]  font-semibold leading-tight mb-[20px];
}

.howitwork{
   @apply bg-[#EFFAFF] py-12;
}
.howitwhitebox{
    @apply relative;
    box-shadow: 0 0.5rem 1rem rgba(0,0,0,0.15);

}
.howitwhitebox::before{
    @apply w-[100px] h-[127px] absolute block -right-[18px] -top-[46px];
    content: "";
    background: url(../../img/split_SGA_LP1/shape-c.png);
}
.howitwork h2{
    @apply text-[30px] md:text-[40px] font-bold leading-tight mb-1;
}
.howitwork h4{
    @apply text-[24px]  font-[500] my-2 text-[#212529];
}
.steps{
    @apply bg-white py-12;
 }
 .steps-col{
    @apply p-6;
    border-bottom: 6px solid #dc6e6d;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    min-height: 180px;
 }
 .steps h3{
    @apply font-bold text-[18px];
    background: -webkit-linear-gradient(#733D7A, #FF735C);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
 }
 .steps p{
    @apply text-[13px];
 }
 .affordhealth{
   
 }
 .affordhealth h2{
    @apply text-[30px] md:text-[40px] font-bold leading-tight mb-[20px];
}
.review-box{
    @apply p-4 bg-[#ffffff];
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}
 .whychoose{
    @apply py-20 bg-[#ffffff]
 }
 .whychoose h2{
    @apply text-[30px] md:text-[40px] font-bold leading-tight mb-[20px];
}
.whytabs{
   @apply py-10 px-[10px] border-b-[1px] border-solid border-b-[#dee2e6];
}
.whytabs:hover{
    @apply bg-[#EFFAFF]
}

.whytabs:hover h3 {
    @apply text-[#FF735C]
}
.whytabs:hover .curvebox{
    @apply bg-[#FF735C]
}
.curvebox{
    @apply w-[50px] h-[50px] bg-[#ADADAD] font-bold flex items-center justify-center text-white;
    font-size: 1.5rem;
    border-radius: 50px 50px 50px 0px;
}
.whytabs h3{
    @apply font-bold md:text-2xl text-[18px];
}

.whytabs p{
    @apply font-normal text-[15px];
}
.tab-content {
    display: none;
}
 .tab-content.active-content {
    display: block;
}

 .AffordableCareAct{
    @apply bg-[#EFFAFF] py-20;
 }
 .AffordableCareAct h2{
    @apply text-[22px] md:text-[35px]  font-semibold leading-tight mb-[20px];
}

.testm-img{
    @apply relative;
}
.testm-img img {
    @apply relative z-[4];
    border-radius: 0 10px 30px 10px
}
.testm-img::before{
    @apply absolute w-[116px] h-[136px] -top-[35px] -left-[35px] z-[1];
    content: "";
    background: url(../../img/split_SGA_LP1/testi_befr.webp) no-repeat;
    background-size: 80px;
}
.testimonials{
    @apply font-semibold w-4/6;
}

.text-primary-color{
    @apply text-[#FF735C]
}

.whatarewaiting{
    @apply  py-20;
    background: #FFE7E3 url(../../img/split_SGA_LP1/testi-bg.webp) no-repeat;
    background-size: cover;
    background-position: left bottom; 
}

.whatarewaiting h3{
    @apply text-[22px] md:text-[32px] font-bold leading-tight mb-2;
}

.whatarewaiting h4{
    @apply text-[20px] font-normal leading-tight mb-2;
}

.faq{
    @apply bg-[#ffffff] py-20;
    background: url(../../img/split_SGA_LP1/faq-bg.webp) no-repeat;
    background-size: cover;
    background-position: left bottom; 
}
.faq h3{
    @apply text-[24px] md:text-[32px]  font-bold leading-tight mb-2;
}

.faq h4{
    @apply text-[22px] md:text-[28px] font-[500] leading-tight mb-2 text-[#FF735C];
}

.faq h5{
    @apply text-[20px] md:text-[24px] font-normal leading-tight mb-2 mt-6 md:mt-12;
}
  
      /* accordion */

      .panel {
        @apply mb-4 hidden bg-white;
    
      }
      .active + .panel {
        @apply block -mt-[16px] ;
        
      }
    
    .accordion-header{
        @apply relative block bg-white text-black p-4 shadow-lg border border-[#dee2e6] mb-4;
    }
    .accordion-header::after{
        background-image:url(../../img/split_SGA_LP1/accordplus.png);
        content: ' ';
        @apply absolute right-3 h-5 w-5 bg-center bg-contain bg-no-repeat mt-1;
    }
    .accordion-header.active{
        @apply bg-[#FF735C] text-white;
    }
    
    .accordion-header.active::after{  
        @apply transition ease-in-out duration-300 rotate-180 fill-white absolute right-3 h-5 w-5 bg-center bg-contain bg-no-repeat;
        background-image:url(../../img/split_SGA_LP1/accordminus.png);
        content:' ';
        top:24%;
    }

    /* accordian end */
    .btn-comm {
        @apply bg-[#0db900]  p-[10px] rounded-lg inline-flex justify-center text-center cursor-pointer text-white text-[18px] relative overflow-hidden border-none min-w-[275px]

    }
    .btn-green-hover:hover{
        background-color:#0a8f00;
    }
    .btn-black-hover:hover{
        background-color: #000000;
    }

    .btn-comm img{
        height: 20px;
        margin-top: 5px;
    }

    .pulse-anime{
        animation: btn_anime 1.5s cubic-bezier(0.57, 0.31, 0.36, 1) infinite;
        border: 2px solid #808080;
    }
    @keyframes btn_anime {
        0% {
            /* transform: scale(.9); */
            box-shadow: #00a700 0 0 0 0;
        }
        70% {
            transform: scale(1);
        }
        100% {
            /* transform: scale(.9); */
            box-shadow: #ff69b400 0 0 0 20px;
        }
    }

    
    .btn-comm:disabled {
        background: #b1b0b0
    } 
    .btn-comm:hover::after {
        transform: translateX(0%);
        transition: all ease-in 0.3s
    }

    
 footer{
    @apply  bg-[#FF735C] text-[#EFFAFF] py-12;
 }
 footer p{
    @apply pb-4 text-sm;
 }

 .copyright{
    @apply bg-[#ffffff] py-[24px];
}
.copyright p{
    @apply text-sm mb-1;
}
.copyright ul li{
    @apply inline text-[#FF735C] underline px-1 text-sm hover:text-[#0d6efd]
}

:root {
    --animate-duration: 1s;
    --animate-delay: 1s;
    --animate-repeat: 1;
  }
  .animate__animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-duration: var(--animate-duration);
    animation-duration: var(--animate-duration);
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }
  
  .animate__animated.animate__delay-2s {
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
    -webkit-animation-delay: calc(var(--animate-delay) * 2);
    animation-delay: calc(var(--animate-delay) * 2);
  }
  @-webkit-keyframes fadeInUp {
    0% {
      opacity: 0;
      -webkit-transform: translate3d(0, 100%, 0);
      transform: translate3d(0, 100%, 0);
    }
    to {
      opacity: 1;
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
    }
  }
  @keyframes fadeInUp {
    0% {
      opacity: 0;
      -webkit-transform: translate3d(0, 100%, 0);
      transform: translate3d(0, 100%, 0);
    }
    to {
      opacity: 1;
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
    }
  }
  .animate__fadeInUp {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
  }

  .pulse {
    animation: pulse-animation 2s infinite;
    -webkit-animation: pulse-animation 2s infinite;
  }

  @-webkit-keyframes pulse-animation {
    0% {
        box-shadow: 0 0 0 0px rgba(255, 41, 41, 0.571);
        transform: scale(1);
        opacity: 1;
    }
    100% {
        box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
        transform: scale(1);
        opacity: 1;
    }
  }
  
  @keyframes pulse-animation {
    0% {
      box-shadow: 0 0 0 0px rgba(255, 41, 41, 0.571);
      transform: scale(1);
      opacity: 1;
    }
  
    100% {
      box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
      transform: scale(1);
      opacity: 1;
    }
  }

  .shadow {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}
.form-select{
    @apply  mb-6 w-full h-[55px] block bg-white text-sm font-normal text-[#212529] rounded-md py-2 pr-9 pl-3;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    border: 1px solid #ced4da;
}
.form-input{
    @apply  mb-6 w-full h-[55px] block bg-white text-sm font-normal text-[#212529] rounded-md py-2 pr-9 pl-3;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    border: 1px solid #ced4da;
}

/* .circle-item.selected.blue {

    border: 6px solid #733d7a;
     -webkit-transition: all ease-in 0.5s;
     -o-transition: all ease-in 0.5s;
     transition: all ease-in 0.5s;
    
  } */

  .marital-circle.selected-border {
      border: 6px solid #733d7a !important;
     -webkit-transition: all ease-in 0.5s !important;
     -o-transition: all ease-in 0.5s !important ;
     transition: all ease-in 0.5s !important;
    
  }

  
  .blue-border {
    border: 3px solid #007bff; /* Blue border */
  }
  

}
