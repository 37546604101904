@tailwind base;
@tailwind components;
@tailwind utilities;
@import url(subpage.css);

.hide{
    display: none;
}
.show{
    display: block;
}

@layer base {
    html{
        /* font-family: "Roboto", sans-serif; */
    }
   }
 
 @layer components{
    
    
     .supporter-dp{
         @apply w-[35px] md:w-10 h-[35px] md:h-10 rounded-full bg-gray-300 flex mr-2 text-center border border-gray-300 overflow-hidden;
     }

     .supporter-ndp{
        @apply w-[35px] md:w-10 h-[35px] md:h-10  rounded-full flex mr-2 text-center overflow-hidden;
    }

     .chater-prof {
        @apply flex items-end max-w-[90%];
    }

    .text-msg{
        @apply bg-gray-200 p-3.5 px-4 mt-1 inline-block rounded-md text-base cursor-pointer;
    }
    .text-msg-1{
        @apply bg-gray-200 py-2.5 px-4 mt-1 inline-block rounded text-base cursor-pointer text-[14px] md:text-[16px];
    } 
    .btn-spc {
        @apply py-5 px-2.5;
    }
    
    .action-btn{
        @apply bg-blue-500 py-3.5 md:px-10 px-[23px] text-white rounded-full font-bold;
    }

    

    .slide-top {
        animation: slide-top .5s cubic-bezier(.25,.46,.45,.94) both;
    }

    @keyframes slide-top {
        0% {
            transform: translateY(0);
        }
        
        100% {
            transform: translateY(-5px);
        }
    }

    .anime-pulse{
        animation: zoom-in-out 1s ease-in-out infinite;
    }

    @keyframes zoom-in-out{
        0% {
            transform: scale(.9);
        }
        
        50% {
            transform: scale(1);
        }
        100% {
            transform: scale(.9);
        }
    }


    label:after {
        /* content: '+'; */
        position: absolute;
        right: 1em;
        top: 0.5rem;
        color: #000;
        font-size: 23px;
      }
      
      input:checked + label:after {
        /* content: '-'; */
        position: absolute;
        right: 1em;
        top: 0.5rem;
        line-height: 1.8em;
        color: #000;
        font-size: 23px;
      }
      
      .dp-client{
        @apply w-[35px] md:w-10 h-[35px] md:h-10 rounded-full bg-gray-300 block mr-2 text-center border border-gray-300 overflow-hidden;
      }
      /* accordion */

.panel {
    @apply mb-4 hidden;

  }
  .active + .panel {
    @apply block -mt-[10px];
    
  }
.accordion-header{
    @apply relative block bg-white text-black p-4 shadow-lg border-b border-gray-50 mb-3;
}
.accordion-header::after{
    background-image:url(../img/ACA_SPH_V11A/accordian-icon.svg);
    content: ' ';
    @apply absolute right-3 h-5 w-5 bg-center bg-contain bg-no-repeat mt-1;
}
.accordion-header.active::after{  
    @apply transition ease-in-out duration-300 rotate-180 fill-white absolute right-3 h-5 w-5 bg-center bg-contain bg-no-repeat;
    background-image:url(../img/ACA_SPH_V11A/accordian-icon.svg);
    content:' ';
    top:24%;
}
.panel ul{
    @apply block m-0 p-0 mt-2 pl-5;
}
.panel ul li{
    @apply block m-0 p-0 pl-8 text-base bg-no-repeat bg-left;
    background-image:url(../img/ACA_SPH_V11A/li.png);
}
/* accordian end */



 }