

.termssection {
    @apply text-[10px] sm:text-[13px] relative;
}

.termssection .terms-box .initial {
    @apply absolute transform -rotate-90 bg-[#FF735C] leading-[8px] rounded-tl-md rounded-tr-md left-[-38px] top-[30px];
}

 .terms-box {
    @apply border border-[#F5B8AD];
}

.termssection .terms-box.disabled {
    @apply border-[#999] opacity-80 text-[#999] border;
}


.termssection .terms-box.disabled .initial {
    @apply bg-[#999];
}

.termssection input {
    @apply w-[25px] h-[25px] relative top-[4px];
}




.sticky-bottom {
    @apply sticky bottom-0 z-[1020] sm:sticky sm:bottom-0 sm:z-[1020];
}

.sticky-in-terms {
    @apply bg-[#ffe6e2];
}

.scroll-contents p{
    @apply mb-4;
}

.scroll-contents a{
    @apply underline text-blue-700;
}