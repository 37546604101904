.pageheader{
    @apply rounded-r-lg rounded-t-lg bg-white -mt-2 relative border-[#dee2e6] border-b-[1px] border-solid py-2
}
.pageheader h3{ 
    @apply  text-[18px] text-black font-medium;
}

.subpagecontent h4{ 
    @apply  text-[24px] text-black font-medium mb-6;
}
.sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
}