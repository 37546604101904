.doctordetails h1{ 
    @apply  text-[18px] md:text-[24px] text-[#FF735C] font-semibold my-6 text-center;
}

.doctordetails h3{ 
    @apply  text-[18px] md:text-[22px] text-black font-bold mb-4 relative;
}
.doctordetails h3 span{
    @apply bg-white  z-10 relative pr-2;
}
.doctordetails h3::after {
    @apply h-[1px] w-full absolute top-4 left-0 z-0 bg-[#FF735C];
    content: "";
}
.privacyg-dr{
    @apply  bg-[url("../../img/split_SGA_LP1/pguard.png")] bg-right bg-no-repeat ;
    background-size: 110px;
}

.radio-but{
    @apply   text-gray-500 bg-white border border-[#ced4da] rounded-lg cursor-pointer  peer-checked:text-white peer-checked:border-[#ff735c] peer-checked:bg-[#ff735c] hover:text-white hover:bg-[#ff735c] hover:border-[#ff735c];
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;

}
