@font-face {
    font-family: 'Poppins';
    src: url('../../../assets/fonts/Poppins-Black.woff2') format('woff2'),
        url('../../../assets/fonts/Poppins-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../../../assets/fonts/Poppins-Italic.woff2') format('woff2'),
        url('../../../assets/fonts/Poppins-Italic.woff2') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../../../assets/fonts/Poppins-BoldItalic.woff2') format('woff2'),
        url('../../../assets/fonts/Poppins-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../../../assets/fonts/Poppins-BlackItalic.woff2') format('woff2'),
        url('../../../assets/fonts/Poppins-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../../../assets/fonts/Poppins-ExtraBoldItalic.woff2') format('woff2'),
        url('../../../assets/fonts/Poppins-ExtraBoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../../../assets/fonts/Poppins-ExtraLightItalic.woff2') format('woff2'),
        url('../../../assets/fonts/Poppins-ExtraLightItalic.woff') format('woff');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../../../assets/fonts/Poppins-Bold.woff2') format('woff2'),
        url('../../../assets/fonts/Poppins-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../../../assets/fonts/Poppins-ExtraBold.woff2') format('woff2'),
        url('../../../assets/fonts/Poppins-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../../../assets/fonts/Poppins-ExtraLight.woff2') format('woff2'),
        url('../../../assets/fonts/Poppins-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../../../assets/fonts/Poppins-LightItalic.woff2') format('woff2'),
        url('../../../assets/fonts/Poppins-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../../../assets/fonts/Poppins-Regular.woff2') format('woff2'),
        url('../../../assets/fonts/Poppins-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../../../assets/fonts/Poppins-Light.woff2') format('woff2'),
        url('../../../assets/fonts/Poppins-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../../../assets/fonts/Poppins-SemiBold.woff2') format('woff2'),
        url('../../../assets/fonts/Poppins-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../../../assets/fonts/Poppins-MediumItalic.woff2') format('woff2'),
        url('../../../assets/fonts/Poppins-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../../../assets/fonts/Poppins-Medium.woff2') format('woff2'),
        url('../../../assets/fonts/Poppins-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../../../assets/fonts/Poppins-SemiBoldItalic.woff2') format('woff2'),
        url('../../../assets/fonts/Poppins-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../../../assets/fonts/Poppins-ThinItalic.woff2') format('woff2'),
        url('../../../assets/fonts/Poppins-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../../../assets/fonts/Poppins-Thin.woff2') format('woff2'),
        url('../../../assets/fonts/Poppins-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}
.content-section::before{
    content: "";
    width: 2px;
    height: 100%;
    background: #ffffff24;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    transform: translateX(77px);
    }
    .questitem{
        position: relative;
    }
    .questitem::before {
      content: "";
      width: 25px;
      height: 25px;
      background: #fff;
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      border-radius: 50%;
      transform: translateX(-95px);
      transition:all ease-in 0.5s ;
      animation:pulse 1500ms infinite;

      @keyframes pulse{
        0%{
          box-shadow: #59d4fa 0 0 0 0;
        }
        75%{
          box-shadow:#ff69b400 0 0 0 16px;
        }
      }
     
      
    }
    .questitem.active::before {
      background: #fff;
      transition:all ease-in 0.5s ;
      background-position: center center;
      animation: initial;
     } 
