.privacy h1{ 
    @apply  text-[22px] md:text-[32px] text-[#FF735C] font-semibold my-6 text-left;
}

.privacy h4{ 
    @apply  text-[18px] text-black font-semibold my-2 relative;
}
.privacy ul{
    @apply pl-8
}
.privacy ul li{
    @apply list-item my-1 list-disc text-sm;
}
.privacy p{
    @apply  text-sm mb-4;
}

